<template>
    <Popover class="">
        <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="flex items-center flex-1">
                <div class="flex items-center justify-between w-full md:w-auto">
                    <router-link to="/">
                        <span class="sr-only">Cuéntame Cómo</span>
                        <img class="h-24 w-auto xl:h-28" :src="require('@/assets/logo-cuentame-como-circular.png')" alt="Cuéntame Cómo" />
                    </router-link>
                    <div class="-mr-2 flex items-center md:hidden">
                        <PopoverButton class="bg-white rounded-md p-4 inline-flex items-center justify-center text-morado hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:morado">
                            <span class="sr-only">Abrir menú</span>
                            <MenuIcon class="h-8 w-8" aria-hidden="true" />
                        </PopoverButton>
                    </div>
                </div>

                <div class="hidden md:block md:ml-20 md:space-x-10">
                    <router-link v-for="item in navigation" :key="item.name" :to="item.href" class="font-medium text-gray-500" :class="getBorderColor(item.color) + ' hover:text-'+item.color + ' ' + kindBorder(item.href)">{{ item.name }}</router-link>
                </div>
            </div>

            <div class="hidden md:block text-right">
                <span class="inline-flex rounded-md shadow-md ring-1 ring-black ring-opacity-5">
                    <a href="https://app.cuentamecomo.mx" target="_blank" class="inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md bg-vino text-white hover:bg-cyan hover:text-white">
                        ENTRAR
                        <ChevronRightIcon class="inline-block w-5 h-5 ml-2" />
                    </a>
                </span>
            </div>
        </nav>

        <!-- MOBILE -->
        <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
            <PopoverPanel focus class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-30">
                <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="px-5 pt-4 flex items-center justify-between">
                        <router-link to="/">
                            <img class="h-20 w-auto" :src="require('@/assets/logo-cuentame-como-circular.png')" alt="Cuéntame Cómo" />
                        </router-link>
                        <div class="-mr-2">
                            <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-morado hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-morado">
                                <span class="sr-only">Cerrar menú</span>
                                <XIcon class="h-6 w-6" aria-hidden="true" />
                            </PopoverButton>
                        </div>
                    </div>

                    <div class="px-2 pt-10 pb-6 space-y-1">
                        <router-link v-for="item in navigation" :key="item.name" :to="item.href" class="block px-3 py-2 rounded-md text-base font-medium space-x-2" :class="'text-'+item.color">
                            <ChevronRightIcon class="w-4 h-4 inline-block" />
                            {{ item.name }}
                        </router-link>
                    </div>
                    
                    <a href="https://app.cuentamecomo.mx" target="_blank" class="inline-flex items-center ml-5 mb-10 px-4 py-2 border border-transparent text-xs font-medium rounded-md bg-vino text-white hover:bg-cyan hover:text-white">
                        ENTRAR A PLATAFORMA
                        <ChevronRightIcon class="inline-block w-5 h-5 ml-2" />
                    </a>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon, XIcon, ArrowNarrowRightIcon, DocumentTextIcon } from '@heroicons/vue/outline'
import { StarIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import Materials from '@/components/home/Materials'
import Sections from '@/components/home/Sections'

const navigation = [
	{ name: 'Acerca de', href: '/acerca-de', color:'azul' },
	{ name: 'Apps', href: '/material-didactico', color:'vino' },
	{ name: 'Escuelas', href: '/escuelas', color:'cyan' },
	{ name: 'Padres de familia', href: '/padres-de-familia', color:'amarillo-dark' },
	{ name: 'Asesorías', href: '/asesoria-psicologica', color:'verde' },
	{ name: 'Blog', href: '/blog', color:'cyan' },
]

const bgAzul = 'border-azul';
const bgAmarilloDark = 'border-amarillo-dark';
const bgVino = 'border-vino';
const bgVerde = 'border-verde';
const bgCyan = 'border-cyan';

export default {
	components: {
		Popover,
		PopoverButton,
		PopoverPanel,
		MenuIcon,
		XIcon,
		StarIcon,
		Materials,
		Sections,
		ChevronRightIcon,
		ArrowNarrowRightIcon,
		DocumentTextIcon
	},
    props: ['actual'],
	setup(props) {
        function kindBorder(str) {
            if (props.actual == str) {
                return 'pb-2 border-b-3';
            } else {
                return 'pt-2 border-t-3';
            }
        }

        function getBorderColor(str) {
            if (str == 'azul') return bgAzul;
            if (str == 'vino') return bgVino;
            if (str == 'cyan') return bgCyan;
            if (str == 'amarillo-dark') return bgAmarilloDark;
            if (str == 'verde') return bgVerde;
        }

		return {
			navigation,
            kindBorder,
            getBorderColor
		}
	},
}
</script>