<template>
    <div :class="state.home ? 'bg-morado' : 'bg-white'">
		<div class="mx-auto max-w-7xl px-4 sm:px-6" :class="state.home ? 'py-20' : 'py-12'">
            <template v-if="home">
                <div class="text-center tracking-tight font-extrabold text-3xl xl:text-4xl text-morado-light">
                    Apps
                </div>
                <div class="text-center text-morado-light text-opacity-80 sm:px-12 md:px-60 xl:px-72 leading-5 mt-6 sm:text-lg">
                    Hablar de sexualidad con niñas y niños, promover una educación sexual oportuna e integral, acorde a las diferentes edades.
                </div>
            </template>

            <div class="grid sm:grid-cols-3 mt-8 md:mt-12 sm:gap-12 lg:gap-28 space-y-12 sm:space-y-0">
                <div>
                    <router-link to="/material-didactico/yo-me-quiero-yo-me-cuido">
                        <img class="shadow-lg rounded-lg" :src="require('@/assets/material-didactico/portada-1.jpg')" alt="Yo me quiero, yo me cuido" />
                        <div class="flex justify-center mt-8">
                            <button class="py-2 px-6 bg-vino-dark text-white rounded-md shadow-md text-opacity-90 hover:text-opacity-100 flex items-center space-x-2 text-sm">
                                <div>Conoce la App</div>
                                <ChevronRightIcon class="w-5 h-5"  />
                            </button>
                        </div>
                    </router-link>
                    <div class="mt-4 flex space-x-2 justify-center items-center bg-white p-2 rounded-full">
                        <a href="https://apps.apple.com/us/app/yo-me-quiero-yo-me-cuido/id1608936103" target="_blank" class="block"><img class="h-10" :src="require('@/assets/material-didactico/appstore.png')" alt="App Store" /></a>
                        <a href="https://play.google.com/store/apps/details?id=mx.cuentamecomo.yomequiero" target="_blank" class="block"><img class="h-10" :src="require('@/assets/material-didactico/playstore.png')" alt="Play Store" /></a>
                    </div>
                </div>
                <div>
                    <router-link to="/material-didactico/como-soy">
                        <img class="shadow-lg rounded-lg" :src="require('@/assets/material-didactico/portada-2.jpg')" alt="¿Cómo soy?" />
                        <div class="flex justify-center mt-8">
                            <button class="py-2 px-6 bg-cyan-dark text-white rounded-md shadow-md text-opacity-90 hover:text-opacity-100 flex items-center space-x-2 text-sm">
                                <div>Conoce la App</div>
                                <ChevronRightIcon class="w-5 h-5"  />
                            </button>
                        </div>
                    </router-link>
                    <div class="mt-4 flex space-x-2 justify-center items-center bg-white p-2 rounded-full">
                        <a href="https://apps.apple.com/us/app/c%C3%B3mo-soy/id1608936371" target="_blank" class="block"><img class="h-10" :src="require('@/assets/material-didactico/appstore.png')" alt="App Store" /></a>
                        <a href="https://play.google.com/store/apps/details?id=mx.cuentamecomo.comosoy" target="_blank" class="block"><img class="h-10" :src="require('@/assets/material-didactico/playstore.png')" alt="Play Store" /></a>
                    </div>
                </div>
                <div>
                    <router-link to="/material-didactico/una-nueva-vida">
                        <img class="shadow-lg rounded-lg" :src="require('@/assets/material-didactico/portada-3.jpg')" alt="una nueva vida" />
                        <div class="flex justify-center mt-8">
                            <div class="py-2 px-6 bg-verde-dark text-white rounded-md shadow-md text-opacity-90 hover:text-opacity-100 flex items-center space-x-2 text-sm">
                                <div>Conoce la App</div>
                                <ChevronRightIcon class="w-5 h-5"  />
                            </div>
                        </div>
                    </router-link>
                    <div class="mt-4 flex space-x-2 justify-center items-center bg-white p-2 rounded-full">
                        <a href="https://apps.apple.com/us/app/una-nueva-vida/id1608935462" target="_blank" class="block"><img class="h-10" :src="require('@/assets/material-didactico/appstore.png')" alt="App Store" /></a>
                        <a href="https://play.google.com/store/apps/details?id=mx.cuentamecomo.unanuevavida" target="_blank" class="block"><img class="h-10" :src="require('@/assets/material-didactico/playstore.png')" alt="Play Store" /></a>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import { GiftIcon, StarIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import { reactive } from 'vue'

export default {
    components: {
        GiftIcon,
        StarIcon,
        ChevronRightIcon
    },
    props: ['home'],
    setup(props) {
        const state = reactive({
            home: props.home
        })
        return {
            state
        }
    },
}
</script>