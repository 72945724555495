<template>
	<div class="relative bg-gray-50 pt-12 px-4 sm:px-6 lg:pt-16 pb-12 lg:pb-36 lg:px-8">
		<div class="absolute inset-0">
			<div class="bg-white h-1/3 sm:h-2/3" />
		</div>
		<div class="relative max-w-7xl mx-auto">
			<div class="text-center">
				<h2 class="text-3xl tracking-tight font-extrabold text-cyan sm:text-4xl">
					Nuestra propuesta
				</h2>
				<p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
					En todas las épocas los adultos han enfrentado diferentes retos y dificultades al educar a niñas y niños.
				</p>
			</div>
			<div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
				<div v-for="post in posts" :key="post.title" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
					<div class="flex-shrink-0">
						<img class="h-48 w-full object-cover" :src="require('@/assets/home/'+post.imageUrl)" alt="" />
					</div>
					<div class="flex-1 p-6 flex flex-col justify-between" :class="getBackColor(post.color)">
						<div class="flex-1">
							<router-link :to="post.href" class="block mt-2">
								<p class="text-xl font-semibold text-white">
									{{ post.title }}
								</p>
								<p class="mt-3 text-base text-white text-opacity-80">
									{{ post.description }}
								</p>
							</router-link>
						</div>
						<router-link :to="post.href" class="mt-6 py-2 px-3 w-28 rounded bg-white shadow text-sm font-medium flex items-center cursor-pointer" :class="'text-'+post.color + ' hover:text-'+post.color+'-dark'">
							<div>Ver más</div>
							<ChevronRightIcon class="w-6 h-6 ml-2"  />
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const posts = [
	{
		title: 'Asesoría psicológica',
		href: '/asesoria-psicologica',
		description: 'Contamos con la colaboración y apoyo de la Licenciada en psicología  clínica  Alejandra Fernández.',
		imageUrl: 'asesoria-psicologica.jpg',
		color: 'verde'
	},
	{
		title: 'Acerca de',
		href: '/acerca-de',
		description: 'Objetivos y funciones del programa. Conoce acerca de nuestra experiencia.',
		imageUrl: 'acerca-de.jpg',
		color: 'azul'
	},
	{
		title: 'Material didáctico',
		href: '/material-didactico',
		description: 'Contamos con libros y juegos interactivos, para la educación sexual integral de niñas y niños de 6 a 12 años y prevención de abuso sexual de 5 a 8 años.',
		imageUrl: 'material-didactico.jpg',
		color: 'vino'
	},
];

const bgAzul = 'bg-azul';
const bgAmarilloDark = 'bg-amarillo-dark';
const bgVino = 'bg-vino';
const bgVerde = 'bg-verde';
const bgCyan = 'bg-cyan';

import { ChevronRightIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ChevronRightIcon
	},

	setup() {
		function getBackColor(str) {
            if (str == 'azul') return bgAzul;
            if (str == 'vino') return bgVino;
            if (str == 'cyan') return bgCyan;
            if (str == 'amarillo-dark') return bgAmarilloDark;
            if (str == 'verde') return bgVerde;
        }

		return {
			posts,
			getBackColor
		}
	},
}
</script>